import icon from './icon.png';
import icon_svg from './icon.svg';
import './App.css';
import {useEffect, useState} from "react";

import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago';

import en from 'javascript-time-ago/locale/en'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faClock, faDownload, faEye, faEyeSlash, faWarning} from "@fortawesome/free-solid-svg-icons";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {saveAs} from 'file-saver'

TimeAgo.addDefaultLocale(en)

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [post, setPost] = useState({});
  const [postURL, setPostURL] = useState('');
  const [author, setAuthor] = useState({});
  const [showTags, setShowTags] = useState(false);
  const [meta, setMeta] = useState({});
  const [showDesk, setShowDesk] = useState(false);

  const getPost = async () => {
    const response = await fetch('/api/random');
    return await response.json();
  }

  const getAuthor = async (id) => {
    const response = await fetch(`https://e621.net/users/${id}.json`);
    return await response.json();
  }

  useEffect(() => {
    const getPosts = async () => {
      const postsResp = await getPost();
      setPost(postsResp);
    };

    getPosts();
  }, []);

  useEffect(() => {
    if (post?.meta) {
      setMeta(post.meta);
    }
    if (!post?.description) {
      setShowDesk(false);
    }
    if (post?.post_url) {
      setPostURL(post.post_url);
    }
    if (post?.uploader_id) {
      const authorResp = getAuthor(post.uploader_id);
      authorResp.then((author) => {
        setAuthor(author);
      });
    }
    if (post.tag_string) {
      // Replace spaces with commas
      post.tag_string = post.tag_string.split(/[ ,]+/).filter(function (v) {
        return v !== ''
      }).join(', ')
    }
    setIsLoading(false);
  }, [post]);

  useEffect(() => {
    if (showDesk && showTags) {
      setShowDesk(false);
    }
  }, [showTags]);

  useEffect(() => {
    if (showDesk && showTags) {
      setShowTags(false);
    }
  }, [showDesk]);

  const handleNext = async () => {
    setIsLoading(true);
    setPost({});
    setPostURL('');
    const postsResp = await getPost();
    setPost(postsResp);
  }

  const copyToClipboard = () => {
    fetch(postURL)
      .then(async response => {
        const blob = await response.blob()
        await navigator.clipboard.write([new ClipboardItem({[`web ${blob.type}`]: blob})])
      })
  }

  const downloadImage = () => {
    saveAs(postURL, `e621-${post.id}.${post.file_ext}`);
  }

  const reportPost = () => {
    const confirmation = window.confirm("Are you sure you want to report this post? (NSFW content)");
  }

  return (
    <div className="App">
      <header className="App-header">
        {!isLoading ? (
          <>
            {postURL && (
              <>
                {['webm', 'mp4'].includes(post.file_ext) ? (
                  <video controls autoPlay loop style={{maxHeight: '50vh', maxWidth: '50vw'}}>
                    <source src={postURL} type={`video/${post.file_ext}`}/>
                  </video>
                ) : (
                  <LazyLoadImage
                    placeholderSrc={icon_svg}
                    src={postURL}
                    alt="Image"
                    style={{maxHeight: '50vh', maxWidth: '50vw'}}
                  />
                )}
              </>
            )}
            {post?.id ? (
              <>
                <p style={{color: "white"}}>
                  <a href={`https://e621.net/posts/${post.id}`}>Original Post</a>{" | "}
                  <a href={`https://e621.net/users/${post.uploader_id}`}>
                    {author?.name ? author.name : "Loading..."}
                  </a><br/>
                  <span>
                    Uploaded {post?.created_at ?
                    <ReactTimeAgo date={new Date(post?.created_at)} locale="en-US"/> : "Loading..."}
                  </span><br/>
                  <button onClick={downloadImage}>
                    Download <FontAwesomeIcon icon={faDownload}/>
                  </button>
                  {" "}
                  <button onClick={reportPost}>
                    Report Post <FontAwesomeIcon icon={faWarning}/>
                  </button>
                </p>
                <button onClick={() => setShowTags(!showTags)}>
                  {showTags ? "Hide" : "Show"} Tags <FontAwesomeIcon icon={showTags ? faEyeSlash : faEye}/>{' '}
                </button>
                {post.description && (
                  <>
                    <button onClick={() => setShowDesk(!showDesk)}>
                      {showDesk ? "Hide" : "Show"} Description <FontAwesomeIcon
                      icon={showDesk ? faEyeSlash : faEye}/>{' '}
                    </button>
                    {showDesk && (
                      <p>Description: {post.description}</p>
                    )}
                  </>
                )}
                {showTags && (
                  <p>Tags: {post.tag_string}</p>
                )}
              </>
            ) : (
              <h1>Loading...</h1>
            )}
          </>
        ) : (
          <>
            <img src={icon} className="App-logo" alt="logo"/>
            <h1>Loading...</h1>
          </>
        )}
        <p className="read-the-docs">
          Made with <span role="img" aria-label="heart">❤️</span> by <a
          href="https://artucuno.dev">Artucuno</a> {meta?.duration && (
          <>
            {" | "}<small><FontAwesomeIcon icon={faClock}/> {parseInt(meta?.duration)}ms</small>
          </>
        )}
        </p>
      </header>
      <button className="float" onClick={handleNext}>
        <FontAwesomeIcon icon={faChevronRight}/>
      </button>
    </div>
  );
}

export default App;
